import { useEffect, useState } from "react"

const useIsAboveIOS18 = () => {
  const [isAboveiOS18, setIsAboveiOS18] = useState<boolean | null>(null)

  useEffect(() => {
    const getiOSVersion = (): boolean | null => {
      const userAgent = window.navigator.userAgent
      // Regular expression to find the iOS version in the user agent
      const iOSRegex = /OS (\d+)_?(\d+)?/
      const match = userAgent.match(iOSRegex)

      if (match) {
        const majorVersion = parseInt(match[1], 10) // Extract major iOS version
        return majorVersion >= 18
      }
      return null // Not an iOS device or version can't be determined
    }

    setIsAboveiOS18(getiOSVersion())
  }, [])

  return isAboveiOS18
}

export default useIsAboveIOS18
