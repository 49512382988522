import React from "react"
import { Button, Typography, useTheme } from "@mui/material"
import { Container, StepsContainer, Step, Circle } from "../components/styled"
import SettingsIcon from "@mui/icons-material/Settings"
import useDetectSafari from "../hooks/useDetectSafari"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import ReactGA from "react-ga4"

const EnableExtensionSettings: React.FC = () => {
  const theme = useTheme()
  useDetectSafari()
  return (
    <Container>
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        You are lacking permissions
      </Typography>
      <Typography variant="subtitle2" color={theme.palette.grey[300]}>
        Which means the like button will not show up
      </Typography>
      <StepsContainer>
        <Step>
          <Circle>
            <Typography>1</Typography>
          </Circle>
          <Typography>
            Go to iOS settings for Safari{" "}
            <SettingsIcon sx={{ marginBottom: "-4px" }} fontSize="small" />
          </Typography>
        </Step>
        <Step>
          <Circle>
            <Typography>2</Typography>
          </Circle>
          <Typography>
            Go to <strong>Extensions</strong> and select <strong>Likely</strong>
          </Typography>
        </Step>
        <Step>
          <Circle>
            <Typography>3</Typography>
          </Circle>
          <Typography>
            Set permissions, select <strong>Allow</strong> for{" "}
            <strong>All Websites</strong> or <strong>Other Websites</strong>
          </Typography>
        </Step>
        <Step>
          <Circle>
            <Typography>4</Typography>
          </Circle>
          <Typography>
            Toggle “Allow Extension” <strong>OFF</strong> then{" "}
            <strong>ON</strong> <ToggleOnIcon sx={{ marginBottom: "-6px" }} />
          </Typography>
        </Step>
      </StepsContainer>
      <Typography
        align="center"
        color={theme.palette.grey[300]}
        variant="body2"
        sx={{ paddingLeft: "45px", paddingRight: "45px" }}
      >
        Here you can also enable the extension to work on Private Browsing if
        you want
      </Typography>
      <Button
        variant="contained"
        size="large"
        href="/enable/third"
        sx={{
          marginTop: "50px",
        }}
        onClick={() => {
          ReactGA.event({
            category: "Onboarding",
            action: "Clicked to check permissions",
            label: "Enable extension through settings page",
          })
        }}
      >
        Check permissions
      </Button>
    </Container>
  )
}

export default EnableExtensionSettings
