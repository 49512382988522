import React from "react"
import { Box, Typography } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import {
  StepsContainer,
  Step,
  Circle,
  fadeIn,
  BlinkAnimation,
  BlinkingIcon,
} from "../components/styled"
import { Link } from "react-router-dom"
import useDetectSafari from "../hooks/useDetectSafari"
import AddressBarIcon from "../icons/AddressBarIcon"
import ArrowUpwardIcon from "@mui/icons-material/North"
import WarningIcon from "@mui/icons-material/Warning"
import PercentageHeader from "../components/PercentageHeader"
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"
import ReactGA from "react-ga4"

const activateLikely2 = require("../images/activate-likely-2.png")

const TopArrowWrapper = styled("div")`
  position: absolute;
  top: 5px;
  right: 10%;
`

const Image = styled("img")`
  max-width: 400px;
  width: 60%;
`

export const Container = styled("div")`
  animation: ${fadeIn} 0.5s ease-in-out;
  padding-top: 6svh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 100svh;
  max-height: 100svh;
  overflow: hidden;
`

const EnableExtensionSecond: React.FC = () => {
  const theme = useTheme()
  useDetectSafari()
  return (
    <>
      <PercentageHeader progress={66} />
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Always allow on every website</Typography>
          <Typography variant="subtitle2" color={theme.palette.grey[300]}>
            Otherwise the like button will not show up
          </Typography>
        </Box>
        <Image src={activateLikely2} alt="Onboarding guide" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <StepsContainer>
            <Step>
              <Circle>
                <Typography>1</Typography>
              </Circle>
              <Typography>
                Tap the <AddressBarIcon /> in the address bar
              </Typography>
            </Step>
            <Step>
              <Circle>
                <Typography>2</Typography>
              </Circle>
              <Typography>
                Tap Likely{" "}
                <WarningIcon sx={{ marginBottom: "-5px", scale: "90%" }} /> in
                the menu
              </Typography>
            </Step>
            <Box
              sx={{
                m: 1,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <KeyboardDoubleArrowDownIcon />
            </Box>

            <Step>
              <Circle>
                <Typography>3</Typography>
              </Circle>
              <Typography>
                Select <strong>Always allow</strong> then{" "}
                <strong>Always allow on every website</strong>
              </Typography>
            </Step>
          </StepsContainer>
          <Link
            to="/enable/third"
            rel="noopener noreferrer"
            style={{ color: "#000" }}
            onClick={() => {
              ReactGA.event({
                category: "Onboarding",
                action: "Clicked I am struggling button",
                label: "Second onboarding page",
              })
            }}
          >
            <Typography>I am struggling</Typography>
          </Link>
        </Box>
        <TopArrowWrapper>
          <BlinkingIcon animation={BlinkAnimation}>
            <ArrowUpwardIcon
              fontSize="large"
              style={{ color: theme.palette.secondary.main, scale: "1.3" }}
            />
          </BlinkingIcon>
        </TopArrowWrapper>
      </Container>
    </>
  )
}

export default EnableExtensionSecond
