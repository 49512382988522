import React from "react"
import { Typography } from "@mui/material"
import { Container } from "../components/styled"
import { styled, useTheme } from "@mui/material/styles"
import Header from "../components/Header"

export const SubContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 40px;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
`

const NotFound: React.FC = () => {
  const theme = useTheme()

  return (
    <>
      <Header title="Likely" titleFontSize="22px" />
      <Container>
        <SubContainer>
          <Typography variant="h1" fontWeight="bold" sx={{ fontSize: "30px" }}>
            Page not found
          </Typography>
          <Typography variant="subtitle2" color={theme.palette.grey[400]} textAlign="center">
            This page does not exist, please double check the link.
          </Typography>
        </SubContainer>
      </Container>
    </>
  )
}

export default NotFound
