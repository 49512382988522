import React, { ReactNode, useMemo } from "react"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"

interface CustomThemeProviderProps {
  children: ReactNode
}

const CustomThemeProvider: React.FC<CustomThemeProviderProps> = ({
  children,
}) => {
  // const prefersDarkMode = window.matchMedia(
  //   "(prefers-color-scheme: dark)"
  // ).matches
  const prefersDarkMode = false

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            main: prefersDarkMode ? "#edf6ff" : "#141414", // Replace with your primary color
          },
          secondary: {
            main: "#E2001B",
          },
          grey: {
            50: "#f5f5f5",
            100: "#e0e0e0",
            200: "#bdbdbd",
            300: "#9a9a9a",
            400: "#757575",
            500: "#616161",
            600: "#424242",
            700: "#212121",
          },
          background: {
            paper: prefersDarkMode ? "#232323" : "#f1f1f1", // Background color for paper elements (like cards)
            default: prefersDarkMode ? "#1a1a1a" : "#ffffff", // Default background color
          },
        },
        typography: {
          fontFamily: ["Outfit", "sans-serif"].join(","),
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: "none",
                boxShadow: "none",
                fontWeight: "bold",
                fontSize: "16px",
                borderRadius: "18px",
                paddingTop: "12px",
                paddingBottom: "12px",
                "&:hover": {
                  boxShadow: "none",
                },
                "&:focus": {
                  outline: "1px solid #E2001B",
                  outlineOffset: "1px",
                },
              },
            },
            defaultProps: {
              tabIndex: 0,
              disableRipple: true,
              disableElevation: true,
            },
          },
        },
      }),
    [prefersDarkMode]
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default CustomThemeProvider
