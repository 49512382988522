import React from "react"
import { Button, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { fadeIn } from "../components/styled"
import ReactGA from "react-ga4"

const likelyBig = require("../images/Likely-logo.png")

const StyledContainer = styled("div")`
  animation: ${fadeIn} 0.5s ease-in-out;
  position: fixed;
  bottom: 20px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`

const Image = styled("img")`
  max-width: 150px;
  width: 40%;
  margin-bottom: 15svh; // Responsive margin to maintain spacing
`

const StyledButton = styled(Button)`
  border-radius: 20px;
  width: 90%;
  max-width: 300px;
  height: 50px;
  margin-top: 25px;
`

const SuccessPage: React.FC = () => {
  const handleOpenApp = () => {
    ReactGA.event({
      category: "Onboarding",
      action: "Clicked open app on successful onboarding page",
      label: "Successful onboarding",
    })

    window.location.href = "likely://open"
  }

  return (
    <StyledContainer>
      <Image src={likelyBig} alt="Likely icon" />

      <Typography
        align="center"
        sx={{ fontWeight: "bold", fontSize: "24px", width: "90%" }}
      >
        Likely is activated!
      </Typography>
      <Typography
        align="center"
        sx={{
          fontSize: "18px",
          color: "text.secondary",
          marginTop: "5px",
          width: "70%",
        }}
      >
        The like button will now appear on any shopping site
      </Typography>
      <StyledButton
        variant="contained"
        color="secondary"
        onClick={handleOpenApp}
      >
        Open Likely app
      </StyledButton>
    </StyledContainer>
  )
}

export default SuccessPage
