// src/App.tsx
import React, { useEffect } from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import Home from "./pages/Home"
import EnableExtensionFirst from "./pages/EnableExtensionFirst"
import EnableExtensionSecond from "./pages/EnableExtensionSecond"
import EnableExtensionSettings from "./pages/EnableExtensionSettings"
import EnableExtensionFail from "./pages/FailEnableExtension"
import OnboardingSuccess from "./pages/OnboardingSuccess"
import CustomThemeProvider from "./CustomThemeProvider"
import LinkToList from "./pages/LinkToList"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import TermsOfService from "./pages/TermsOfService"
import Contact from "./pages/Contact"
import NotInSafari from "./pages/NotInSafari"
import NoIndex from "./components/NoIndex"
import NotFound from "./pages/NotFound"
import ReactGA from "react-ga4"
import { Helmet } from "react-helmet"
import { useTheme } from "@mui/material/styles"

const TRACKING_ID = "G-8F4XHTX6CL"
ReactGA.initialize(TRACKING_ID)

const AnalyticsTracker: React.FC = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname })
  }, [location])

  return null
}

const GeneralHelmet: React.FC = () => {
  const theme = useTheme()

  return (
    <Helmet>
      {/* this should be the same as the header color */}
      <meta name="theme-color" content={theme.palette.grey[50]} />
    </Helmet>
  )
}

const App: React.FC = () => {
  return (
    <CustomThemeProvider>
      <Router>
        <GeneralHelmet />
        <AnalyticsTracker />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/enable/first"
            element={
              <NoIndex>
                <EnableExtensionFirst />
              </NoIndex>
            }
          />
          <Route
            path="/enable/second"
            element={
              <NoIndex>
                <EnableExtensionSecond />
              </NoIndex>
            }
          />
          <Route
            path="/enable/third"
            element={
              <NoIndex>
                <EnableExtensionSettings />
              </NoIndex>
            }
          />
          <Route
            path="/fail-enable/first"
            element={
              <NoIndex>
                <EnableExtensionFail />
              </NoIndex>
            }
          />
          <Route
            path="/fail-enable/second"
            element={
              <NoIndex>
                <EnableExtensionSettings />
              </NoIndex>
            }
          />
          <Route
            path="/not-in-safari"
            element={
              <NoIndex>
                <NotInSafari />
              </NoIndex>
            }
          />
          <Route
            path="/onboarding-success"
            element={
              <NoIndex>
                <OnboardingSuccess />
              </NoIndex>
            }
          />
          <Route
            path="/list/:id"
            element={
              <NoIndex>
                <LinkToList />
              </NoIndex>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </CustomThemeProvider>
  )
}

export default App
