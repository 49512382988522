import React, { useState } from "react"
import LazyLoad from "react-lazy-load"
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined"
import ReactGA from "react-ga4"

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  background-color: white;
  border: 1px solid rgba(229, 229, 229, 0.3);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
`

interface ProductCardProps {
  title: string
  imageUrl: string
  price: string
  store: string
  productUrl: string
}

const ProductCard: React.FC<ProductCardProps> = ({
  title,
  imageUrl,
  price,
  store,
  productUrl,
}) => {
  const theme = useTheme()
  const [imageError, setImageError] = useState(false)

  const handleImageError = () => {
    setImageError(true)
  }

  return (
    <LazyLoad height={245} width={170} offset={100}>
      <a
        href={productUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
        onClick={() => {
          ReactGA.event({
            category: "User",
            action: "Clicked on a product card in a list",
            label: "Product card",
          })
        }}
      >
        <StyledCard>
          {!imageError ? (
            <CardMedia
              component="img"
              image={imageUrl}
              alt={title}
              height={170}
              sx={{
                objectFit: "cover",
                minHeight: "170px",
              }}
              onError={handleImageError}
            />
          ) : (
            <Box
              sx={{
                minHeight: "170px",
                backgroundColor: "lightGray",
              }}
            />
          )}
          <CardContent sx={{ padding: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                gap: "3px",
              }}
            >
              <Typography
                fontWeight="bold"
                sx={{
                  fontSize: "11px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {title}
              </Typography>
              <Typography
                fontWeight="bold"
                sx={{
                  fontSize: "11px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {price}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                  gap: "2px",
                }}
              >
                <StoreMallDirectoryOutlinedIcon
                  sx={{
                    fontSize: "15px",
                    color: theme.palette.grey[300],
                  }}
                />
                <Typography
                  fontWeight="bold"
                  sx={{
                    fontSize: "11px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: theme.palette.grey[300],
                  }}
                >
                  {store}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </StyledCard>
      </a>
    </LazyLoad>
  )
}

export default ProductCard
