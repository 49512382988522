// First file
import React, { useEffect, useState } from "react"
import {
  Container,
  StepsContainer,
  Step,
  Circle,
  BlinkingIcon,
  BlinkAnimation,
} from "../components/styled"
import { Typography } from "@mui/material"
import ArrowDownwardIcon from "@mui/icons-material/South"
import ArrowUpwardIcon from "@mui/icons-material/North"
import ExtensionIcon from "@mui/icons-material/Extension"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import { styled, useTheme } from "@mui/material/styles"
import AddressBarIcon from "../icons/AddressBarIcon"
import useDetectSafari from "../hooks/useDetectSafari"
import useIsAboveIOS18 from "../hooks/useIsAboveIOS18"
import PercentageHeader from "../components/PercentageHeader"

const activateLikely1old = require("../images/activate-likely-1old.png")
const activateLikely1new = require("../images/activate-likely-1new.png")

const BottomArrowWrapper = styled("div")`
  position: absolute;
  bottom: 0px;
  left: 9%;
`

const Image = styled("img")`
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
  border-radius: 10px;
`

const TopArrowWrapper = styled("div")`
  position: absolute;
  top: 5px;
  left: 4%;
`

const ShadowDivSvh = styled("div")`
  position: absolute;
  height: 100svh;
`

const ShadowDivVh = styled("div")`
  position: absolute;
  height: 100vh;
`

const EnableExtensionFirst: React.FC = () => {
  const theme = useTheme()
  const svhRef = React.useRef<HTMLDivElement>(null)
  const vhRef = React.useRef<HTMLDivElement>(null)
  const [isOnTop, setIsOnTop] = useState(true)
  const isAboveIOS18 = useIsAboveIOS18()
  useDetectSafari()

  useEffect(() => {
    const svhElement = svhRef.current
    const vhElement = vhRef.current

    if (svhElement && vhElement) {
      const svhHeight = svhElement.getBoundingClientRect().height
      const vhHeight = vhElement.getBoundingClientRect().height

      const heightDifference = Math.abs(svhHeight - vhHeight)

      if (heightDifference < 80) {
        setIsOnTop(heightDifference < 75)
      } else {
        setIsOnTop(heightDifference > 100)
      }
    }
  }, [])

  return (
    <>
      <PercentageHeader progress={33} />
      <Container>
        <ShadowDivSvh ref={svhRef} />
        <ShadowDivVh ref={vhRef} />
        <Typography variant="h5">Enable Likely on Safari</Typography>
        <Typography variant="subtitle2" color={theme.palette.grey[300]}>
          We don't sell your data
        </Typography>
        <Image
          src={isAboveIOS18 ? activateLikely1new : activateLikely1old}
          alt="Onboarding guide"
        />
        <StepsContainer>
          <Step>
            <Circle>
              <Typography>1</Typography>
            </Circle>
            <Typography>
              Tap the <AddressBarIcon /> in the address bar
            </Typography>
          </Step>
          <Step>
            <Circle>
              <Typography>2</Typography>
            </Circle>
            <Typography>
              Select <strong>Manage Extensions</strong>
            </Typography>
            <ExtensionIcon fontSize="small" />
          </Step>
          <Step>
            <Circle>
              <Typography>3</Typography>
            </Circle>
            <Typography>
              Toggle on <strong>Likely</strong>
            </Typography>
            <ToggleOnIcon sx={{ marginBottom: "-3px" }} />
          </Step>
        </StepsContainer>
        {isOnTop ? (
          <TopArrowWrapper>
            <BlinkingIcon animation={BlinkAnimation}>
              <ArrowUpwardIcon
                fontSize="large"
                style={{ color: theme.palette.secondary.main, scale: "1.3" }}
              />
            </BlinkingIcon>
          </TopArrowWrapper>
        ) : (
          <BottomArrowWrapper>
            <BlinkingIcon animation={BlinkAnimation}>
              <ArrowDownwardIcon
                fontSize="large"
                style={{ color: theme.palette.secondary.main, scale: "1.3" }}
              />
            </BlinkingIcon>
          </BottomArrowWrapper>
        )}
      </Container>
    </>
  )
}

export default EnableExtensionFirst
