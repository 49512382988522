import React from "react"
import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { styled } from "@mui/material/styles"
import { APP_STORE_LINK } from "../constants"
import ReactGA from "react-ga4"

const ImageAppStore = styled("img")`
  max-width: 220px;
`

interface InformationSectionProps {
  boldText: string
  descriptionText?: string
}

const InformationSection: React.FC<InformationSectionProps> = ({
  boldText,
  descriptionText,
}) => {
  return (
    <Box
      sx={{
        marginTop: "10vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" textAlign="center" fontWeight="bold">
        {boldText}
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        width="250px"
        sx={{ m: 2, mb: 5 }}
      >
        {descriptionText}
      </Typography>
      <Link
        to={APP_STORE_LINK}
        onClick={() => {
          ReactGA.event({
            category: "User",
            action: "Clicked download on app store link",
            label: "App Store image button",
          })
        }}
      >
        <ImageAppStore
          src={require("../images/app-store.png")}
          alt="Download on App Store"
        />
      </Link>
    </Box>
  )
}

export default InformationSection
