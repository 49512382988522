import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Helmet from "react-helmet"
import { APP_STORE_LINK, TIKTOK_LINK } from "../constants"
import { fadeIn } from "../components/styled"
import ReactGA from "react-ga4"

const likelyVideo = require("../images/likely-landing-page.mp4")
const appStore = require("../images/app-store.png")
const tiktokLogo = require("../images/Tiktok-logo.png")

const Container = styled("div")`
  animation: ${fadeIn} 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100svh;
  gap: 50px;
  padding-top: 14svh;
`

const MainDiv = styled("div")`
  width: 100%;
  max-width: 1200px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 50px;
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`

const FollowDiv = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
`

const TextArea = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  min-width: 300px;
  gap: 20px;
`

const ImageAppStore = styled("img")`
  max-width: 220px;
`

const ImageTikTok = styled("img")`
  max-width: 60px;
`

const StyledVideo = styled("video")`
  width: 300px;
  border-radius: 20px;
`

const Home: React.FC = () => {
  const schemaOrgJSONLD = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: "https://www.joinlikely.com",
    name: "Likely",
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>
      <Header title="Likely" titleFontSize="22px" />
      <Container>
        <MainDiv>
          <TextArea>
            <Typography
              variant="h1"
              align="center"
              style={{ fontSize: "28px", fontWeight: "bold" }}
            >
              Like and save any product from any store
            </Typography>
            <Typography
              variant="h2"
              align="center"
              style={{ fontSize: "16px" }}
            >
              With the Likely app and Safari extension, the like button only
              appears on product pages, letting you easily save favorites to the
              app.
            </Typography>
            <Link
              to={APP_STORE_LINK}
              onClick={() => {
                ReactGA.event({
                  category: "User",
                  action: "Clicked download on app store link",
                  label: "App Store image button on home page",
                })
              }}
            >
              <ImageAppStore src={appStore} alt="Download on App Store" />
            </Link>
            <FollowDiv>
              <Typography variant="body2" align="center">
                Follow us.
              </Typography>
              <Link
                to={TIKTOK_LINK}
                onClick={() => {
                  ReactGA.event({
                    category: "User",
                    action: "Clicked link to tiktok",
                    label: "Home page",
                  })
                }}
              >
                <ImageTikTok src={tiktokLogo} alt="Follow us on TikTok" />
              </Link>
            </FollowDiv>
          </TextArea>
          <StyledVideo autoPlay loop muted playsInline>
            <source src={likelyVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </StyledVideo>
        </MainDiv>
        <Footer />
      </Container>
    </>
  )
}

export default Home
