import React from "react"
import { Button, Typography, useTheme } from "@mui/material"
import { Container } from "../components/styled"
import useDetectSafari from "../hooks/useDetectSafari"
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied"

const FailEnableExtensionFirst: React.FC = () => {
  const theme = useTheme()
  useDetectSafari()
  return (
    <Container>
      <SentimentVeryDissatisfiedIcon fontSize="large"/>
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        You missed a step
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ textAlign: "center", maxWidth: "300px" }}
        color={theme.palette.grey[300]}
      >
        You enabled the extension with the wrong permissions. Which means the
        like button will not show up, it'll be a quick fix!
      </Typography>
      <Button
        variant="contained"
        size="large"
        href="/fail-enable/second"
        sx={{
          marginTop: "50px",
        }}
      >
        Fix permissions
      </Button>
    </Container>
  )
}

export default FailEnableExtensionFirst
