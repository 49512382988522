import React from "react"
import { styled, useTheme } from "@mui/material/styles"
import { fadeIn } from "./styled"

const Container = styled("header")`
  animation: ${fadeIn} 0.5s ease-in-out;
  background-color: transparent;
  position: fixed;
  width: 100%;
  min-height: 30px;
  max-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ProgressBarWrapper = styled("div")`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
`

const ProgressBar = styled("div")<{ progress: number; color: string }>`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background-color: ${({ color }) => color};
  transition: width 0.3s ease-in-out;
`

interface HeaderProps {
  progress: number // Progress percentage
}

const Header: React.FC<HeaderProps> = ({ progress }) => {
  const theme = useTheme() // Access the MUI theme
  return (
    <Container>
      <ProgressBarWrapper
        style={{
          backgroundColor: theme.palette.grey[50], // Light version of primary color
        }}
      >
        <ProgressBar
          progress={progress}
          color={theme.palette.primary.main} // Main primary color
        />
      </ProgressBarWrapper>
    </Container>
  )
}

export default Header
