import React from "react"
import ReactMarkdown from "react-markdown"
import { styled } from "@mui/material/styles"
import { fadeIn } from "../components/styled"

const Container = styled("div")`
  animation: ${fadeIn} 0.5s ease-in-out;
  padding: 16px;
  max-width: 800px;
  margin: 0 auto;
`

const StyledMarkdown = styled(ReactMarkdown)`
  line-height: 1.6;

  h1 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 2em;
  }

  h2 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 1.5em;
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 1.25em;
  }

  p {
    margin-bottom: 16px;
  }

  ul {
    margin-bottom: 16px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 8px;
  }

  strong {
    font-weight: 600;
  }
`

const PrivacyPolicy: React.FC = () => {
  const markdownContent = `
# Likely Privacy Policy

Welcome to Likely's Privacy Policy. We are committed to protecting your privacy and ensuring the security of your information. This policy outlines how we collect, use, and protect your personal data when you use our Likely mobile Safari extension and associated services.

## Data Collection and Use

### Personal Data We Collect:
- **Identifiers**: Such as name and email address when you create an account.
- **Activity Information**: Including products you like, save, and lists you organize.
- **Browser Data**: Pages visited and interactions with products outside of our extension, solely for the purpose of enabling the extension's functionalities.
- **Log Data**: Dates and times of access, pages viewed, links clicked.
- **Communication Data**: Content of your communications with us.
- **Location Information**: Approximate location inferred from your IP address.
- **Usage Data**: Features you use, preferences, interaction with content.

### Purpose of Data Collection:
- To provide, improve, and personalize our services.
- To communicate with you about updates or offers related to Likely.
- To analyze usage patterns and preferences to enhance our extension.

## Legal Basis for Processing:
- **Consent**: You give us consent to process your personal data for specific purposes.
- **Contract Performance**: Processing your data is necessary for the performance of our services that you use or sign up for.
- **Legitimate Interests**: For our legitimate interests in improving our services, provided these do not override your rights and freedoms.
- **Legal Obligations**: To comply with legal obligations to which we are subject.

## Data Sharing and Disclosure
We do not sell your personal data. We may share your information with third-party service providers for the purpose of improving our service, complying with the law, or protecting our rights. We may disclose your personal data to law enforcement agencies or courts when required by law.

## User Rights
You have the following rights regarding your personal data:

- **Right to Access**: Request access to the personal data we hold about you.
- **Right to Rectification**: Request correction of inaccurate or incomplete personal data.
- **Right to Erasure (Right to Be Forgotten)**: Request deletion of your personal data under certain conditions.
- **Right to Restrict Processing**: Request that we limit the processing of your data under specific circumstances.
- **Right to Data Portability**: Request to receive your personal data in a structured, commonly used, and machine-readable format and transfer it to another service provider.
- **Right to Object**: Object to the processing of your personal data for direct marketing or other specific purposes.
- **Right to Withdraw Consent**: Withdraw your consent at any time, where applicable.
- **Right to Lodge a Complaint**: If you believe your privacy rights have been violated, you can file a complaint with a supervisory authority.

### U.S. State-Specific Privacy Rights
Residents of certain U.S. states, such as California and Virginia, have additional rights under privacy laws like the **California Consumer Privacy Act (CCPA)** and **Virginia Consumer Data Protection Act (VCDPA)**:

- **Right to Know**: Request information about the categories and specific pieces of personal data we have collected about you, the purpose for collection, and any third parties with whom we share data.
- **Right to Delete**: Request that we delete the personal data we have collected about you, subject to certain exceptions.
- **Right to Opt Out of Sale/Sharing**: To opt out of the sharing of your personal data for targeted advertising, email us at contact@joinlikely.com.
- **Right to Correct**: Request corrections to inaccurate or incomplete personal information.
- **Right to Non-Discrimination**: We will not discriminate against you for exercising your privacy rights.

### How to Exercise Your Rights
You can exercise your rights by:
- Emailing us at contact@joinlikely.com.

## Data Security
We take precautions to protect your data from unauthorized access, disclosure, alteration, or destruction. We implement appropriate technical and organizational measures to secure your personal data.

## International Data Transfers
Your data may be stored or processed in countries outside of the EU/EEA or U.S., under data protection laws that ensure a similar level of protection. For transfers outside of the EU/EEA, we implement safeguards such as:
- **Standard Contractual Clauses (SCCs)** approved by the European Commission.
- Transfers to countries with adequacy decisions under GDPR.

## Data Retention
We retain your personal data only as long as necessary to provide you with our services. For example:
- **Account data**: Retained for up to 12 months after account deletion.
- **Log data**: Retained for 6 months for security purposes.

## Third-Party Links and Services
Our services may contain links to external websites or services not operated by Likely. We are not responsible for the content or privacy practices of these third parties.

## Children's Privacy
The services are not intended for use by children without the consent of a parent or guardian. If you are a parent of a child under the age of 13 and believe your child has provided personal information to us without your approval, please contact us immediately, providing the email address used. We will promptly remove the information from our systems.

## Contact Information
For questions or concerns about our privacy practices, please contact us at:

Likely Technologies AB
Hägerstensvägen 203
Hägersten, Sweden

Email: contact@joinlikely.com

## Policy Updates
We may update this policy from time to time to reflect changes in our practices or relevant laws. We will notify you of any significant changes.

**Effective Date**: 2024-11-21
`

  return (
    <Container>
      <StyledMarkdown>{markdownContent}</StyledMarkdown>
    </Container>
  )
}

export default PrivacyPolicy
