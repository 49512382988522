import useIsAboveIOS18 from "../hooks/useIsAboveIOS18"

const AddressBarIcon = () => {

  const isAboveiOS18 = useIsAboveIOS18()
  return isAboveiOS18 ? (
    <svg width="16" height="18" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2"
        y="2"
        rx="2"
        ry="2"
        width="12"
        height="7"
        stroke="black"
        fill="none"
        strokeWidth="2"
      />
      <rect x="2" y="12" width="12" height="2" fill="black" />
      <rect x="2" y="16" width="10" height="2" fill="black" />
    </svg>
  ) : (
    <>
      <strong style={{ fontSize: "12px" }}>A</strong>
      <strong style={{ fontSize: "18px" }}>A</strong>
    </>
  )
}

export default AddressBarIcon
