import React, { useEffect } from "react";
import { styled } from "@mui/material/styles"

const logo = require("../images/Likely-logo.png");

const Logo = styled("img")`
  max-width: 100px;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 10svh;
  margin-left: 8vw;
  margin-right: 8vw;
  align-items: center;
`;

const Contact: React.FC = () => {
  // Using useParams to access the dynamic parts of the URL.

  useEffect(() => {
    window.location.href = "mailto:contact@joinlikely.com";
  }, []);

  return (
    <Container>
      <Logo src={logo} alt="Likely logo" />
    </Container>
  );
};

export default Contact;
